import { PropsWithChildren, useEffect } from 'react'
import { Box, Button, Divider, useMediaQuery } from '@mui/material'
import { Card } from '../Card'
import { ContentTitle } from '../ContentTitle'
import { useProposalContext } from '~/contexts'
import { PropsWithWizard } from '../Wizard'
import { ProposalStepper } from './components'
import { Link, useLocation } from 'react-router-dom'
import { EProposalStatus } from '~/graphql/types'
import { theme } from '~/utils'
import { IconChevronLeft } from '@tabler/icons-react'
import { ProposalHeaderInformations } from '../ProposalHeaderInformations'

export type ProposalFlowLayoutProps = {
  nextButtonLabel?: string
}
export const ProposalFlowLayout: React.FC<PropsWithChildren<PropsWithWizard<ProposalFlowLayoutProps>>> = (props) => {
  const { children, goToPage, totalPages } = props
  const { proposal } = useProposalContext()
  const { state } = useLocation()
  const isLowerXs = useMediaQuery('(max-width:500px)')
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    state?.newProposalCreated && goToPage(1)
    window.history.replaceState({}, document.title)
  }, [])

  useEffect(() => {
    proposal && (proposal.status === EProposalStatus.finalized || proposal.status === EProposalStatus.formalization) && goToPage(totalPages - 1)
  }, [])

  return (
    <Card>

      {isLowerMd ? (
        <>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexFlow: isLowerXs ? 'column' : 'row', gap: isLowerXs ? '1rem' : '0' }}>
            <Link to='/app/proposals'>
              <Button size='small' sx={{ margin: '0' }} color='secondary'>
                <IconChevronLeft />
              </Button>
            </Link>
            {proposal ? <ProposalHeaderInformations isDisabledSalesFunnelDropdown={false} align={isLowerXs ? 'start' : 'end'} /> : undefined}
          </Box>
          <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
          <ContentTitle
            style={{ padding: '0px 0px 24px 0px' }}
            title={proposal ? 'Detalhes da proposta' : 'Cadastrar proposta'}
            breadcrumbLinks={{ currentLink: 'Cadastrar propostas', links: [{ href: '/app/proposals/register', label: 'Vendas' }] }}
            description={proposal ? 'Acompanhe e dê continuidade a proposta' : 'Complete os dados para criar uma propostas para seus cliente'}
          />
        </>
      ) : (
        <ContentTitle
          style={{ padding: '0px 0px 24px 0px' }}
          title={proposal ? 'Detalhes da proposta' : 'Cadastrar proposta'}
          rightContent={proposal ? <ProposalHeaderInformations isDisabledSalesFunnelDropdown={false} /> : undefined}
          breadcrumbLinks={{ currentLink: 'Cadastrar propostas', links: [{ href: '/app/proposals/register', label: 'Vendas' }] }}
          description={proposal ? 'Acompanhe e dê continuidade a proposta' : 'Complete os dados para criar uma propostas para seus cliente'}
        />
      )}

      <Divider sx={{ marginBottom: 5 }} />

      <ProposalStepper proposal={proposal} {...props} />

      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {children}
      </Box>
    </Card>
  )
}
