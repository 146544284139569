import { useTheme } from '@emotion/react'
import { Box, Card, MenuItem, Typography } from '@mui/material'
import { IconChevronDown } from '@tabler/icons-react'
import { Dropdown } from '~/components/Dropdown'
import { EProposalStatus, EStage, useUpdateSalesFunnelStageMutation } from '~/graphql/types'
import { translateStageFunnel } from '~/utils'
import { customStagesFormalization, enableStagesByActualStage } from './utils'
import { useProposalContext } from '~/contexts'
import { useCallback } from 'react'
import { LostFunnelStageModal } from '../LostFunnelStageModal'
import { useToggle } from '~/hooks'
import { toast } from 'react-toastify'

type FunnelData = {
  value: EStage
  title: string
  disabled: boolean
}

type ProposalFunnelProps = {
  isDisabledSalesFunnelDropdown: boolean;
}

export const ProposalFunnel: React.FC<ProposalFunnelProps> = ({ isDisabledSalesFunnelDropdown }) => {
  const theme = useTheme()
  const { isTrue: modalIsVisible, setIsTrue: setModalIsVisible, toggle: toggleModal } = useToggle()
  const { proposal, setProposal } = useProposalContext()
  const isCanceledProposal = proposal?.status === EProposalStatus.canceled
  const isCanceledOrPendencie = isDisabledSalesFunnelDropdown || isCanceledProposal

  const [updateFunnelStage, { loading }] = useUpdateSalesFunnelStageMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      if (proposal) {
        toast.success('Etapa de funil alterada!')
        setProposal({
          ...proposal,
          salesFunnel: data.updateSalesFunnelStage.salesFunnel,
          lossReason: data.updateSalesFunnelStage.lossReason,
          rejectedInfo: data.updateSalesFunnelStage.rejectedInfo as any
        })
      }
    },
  })

  const changeFunnelStage = useCallback((newStage: EStage) => {
    updateFunnelStage({
      variables: {
        params: {
          newStage,
          proposalId: proposal?._id || ''
        }
      }
    })
  }, [proposal])

  const isDisabledByStage = useCallback((value: EStage) => {
    if (proposal?.status === EProposalStatus.formalization) {
      if (proposal?.salesFunnel?.stage === EStage.negotiating || proposal?.salesFunnel?.stage === EStage.lost) {
        const optionsEnable = customStagesFormalization[proposal?.salesFunnel?.stage]
        const isEnabled = !optionsEnable.includes(value)
        return isEnabled
      }
      const optionsEnable = enableStagesByActualStage[proposal?.salesFunnel?.stage || EStage.awaitingSignature]
      const isEnabled = !optionsEnable.includes(value)
      return isEnabled
    } else {
      const optionsEnable = enableStagesByActualStage[proposal?.salesFunnel?.stage || EStage.awaitingSignature]
      const isEnabled = !optionsEnable.includes(value)
      return isEnabled
    }
  }, [proposal])

  const funnelOptions: FunnelData[] = [
    {
      value: EStage.lead,
      title: translateStageFunnel(EStage.lead),
      disabled: isDisabledByStage(EStage.lead)
    },
    {
      value: EStage.opportunity,
      title: translateStageFunnel(EStage.opportunity),
      disabled: isDisabledByStage(EStage.opportunity)
    },
    {
      value: EStage.negotiating,
      title: translateStageFunnel(EStage.negotiating),
      disabled: isDisabledByStage(EStage.negotiating)
    },
    {
      value: EStage.negotiationCoolingDown,
      title: translateStageFunnel(EStage.negotiationCoolingDown),
      disabled: isDisabledByStage(EStage.negotiationCoolingDown)
    },

    {
      value: EStage.firstContactAttempt,
      title: translateStageFunnel(EStage.firstContactAttempt),
      disabled: isDisabledByStage(EStage.firstContactAttempt)
    },
    {
      value: EStage.secondContactAttempt,
      title: translateStageFunnel(EStage.secondContactAttempt),
      disabled: isDisabledByStage(EStage.secondContactAttempt)
    },
    {
      value: EStage.thirdContactAttempt,
      title: translateStageFunnel(EStage.thirdContactAttempt),
      disabled: isDisabledByStage(EStage.thirdContactAttempt)
    },
    {
      value: EStage.contactReestablished,
      title: translateStageFunnel(EStage.contactReestablished),
      disabled: isDisabledByStage(EStage.contactReestablished)
    },
    {
      value: EStage.legalAssembly,
      title: translateStageFunnel(EStage.legalAssembly),
      disabled: isDisabledByStage(EStage.legalAssembly)
    },
    {
      value: EStage.awaitingSignature,
      title: translateStageFunnel(EStage.awaitingSignature),
      disabled: isDisabledByStage(EStage.awaitingSignature)
    },
    {
      value: EStage.lost,
      title: translateStageFunnel(EStage.lost),
      disabled: isDisabledByStage(EStage.lost)
    },
    {
      value: EStage.proposalContracted,
      title: translateStageFunnel(EStage.proposalContracted),
      disabled: isDisabledByStage(EStage.proposalContracted)
    },
  ]

  return (
    <>
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <Typography sx={{ color: theme.palette.grey[400] }}>Etapa do funil:</Typography>
        {isCanceledOrPendencie ? (
          <Box sx={{ display: 'flex', border: `1px solid ${theme.palette.grey[400]}`, padding: '.2rem .6rem', borderRadius: '1rem', background: theme.palette.grey[300] }}>
            <Typography sx={{ color: theme.palette.grey[400], fontSize: '14px' }}>
              {translateStageFunnel(proposal?.salesFunnel?.stage as EStage)}
            </Typography>
            <IconChevronDown size={20} color={theme.palette.grey[400]} />
          </Box>
        )
          : (
            <>
              <Dropdown
                $options={{ left: '-100px' }}
                elementClick={
                  <Box sx={{ display: 'flex', border: `1px solid ${theme.palette.yellow.darker}`, padding: '.2rem .6rem', borderRadius: '1rem', cursor: 'pointer' }}>
                    <Typography sx={{ color: theme.palette.yellow.darker, fontSize: '14px' }}>
                      {translateStageFunnel(proposal?.salesFunnel?.stage as EStage)}
                    </Typography>
                    <IconChevronDown size={20} color={theme.palette.yellow.darker} />
                  </Box>
                }>
                <Card sx={{ padding: '1rem', borderRadius: '1rem', border: `1px solid ${theme.palette.grey[300]}` }}>
                  {funnelOptions.map(item => {
                    return (
                      <MenuItem
                        onClick={() => item.value === EStage.lost ? toggleModal() : changeFunnelStage(item.value)}
                        disabled={item.disabled || loading}
                        key={item.value}>
                        {item.title}
                      </MenuItem>
                    )
                  })}
                </Card>
              </Dropdown>
            </>
          )}

      </Box>
      <LostFunnelStageModal setIsVisible={setModalIsVisible} isVisible={modalIsVisible} />
    </>
  )
}
