import * as yup from 'yup'
import { isEmpty } from 'ramda'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { clearString, transformMoney } from '~/utils'
import { brazilStates } from '~/constants'
import { ProposalInfo, PropsWithWizard } from '~/components'
import { Form } from '~/components/Form/Form'
import { Input } from '~/components/Form/Input'
import { Radio } from '~/components/Form/Radio'
import { useProposalContext } from '~/contexts'
import { Select } from '~/components/Form/Select'
import { isPendencyField } from '~/utils/proposal'
import { ActionButtons, CreatingProposalDialog } from './components'
import { documentSchema, personTypeSchema, phoneSchema } from '~/utils/yupSchema'
import { Option, defaultOption, maritalStatusOptions, personTypeOptions } from '~/utils/options'
import { BASE_DATA_FORM_INITIAL_VALUES, BaseDataForm, getProposalSolvedPendencies } from './utils'
import { ECustomerPersonType, EMaritalStatus, EProposalOrigin, EProposalStatus, ProposalCreateGraphqlDto, ProposalCustomerPendenciesFields, ProposalModel, ProposalUpdateGraphqlDto, useDealershipListQuery, useProposalCreateMutation, useProposalUpdateMutation, useSolveProposalCustomerPendenciesMutation } from '~/graphql/types'
import { ApolloError } from '@apollo/client'
import { mapErrorToMessage } from '~/utils/errors'

const getInitialValues = (proposal: ProposalModel | null): BaseDataForm => {
  if (proposal) {
    const { customerRef } = proposal
    const { document, email, phone, name, maritalStatus, nationality, personType, uf, dealershipId, dealershipName } = customerRef

    return {
      name,
      document,
      phone: phone || '',
      email: email || '',
      uf: uf || '',
      nationality: nationality || '',
      dealershipId: dealershipId || '',
      dealershipName: dealershipName || '',
      personType: personType as ECustomerPersonType,
      maritalStatus: maritalStatus as EMaritalStatus
    }
  }

  return BASE_DATA_FORM_INITIAL_VALUES
}

const schema = yup.object().shape({
  phone: phoneSchema,
  document: documentSchema,
  personType: personTypeSchema,
  maritalStatus: yup.string().when('personType', {
    is: (value: ECustomerPersonType) => value === ECustomerPersonType.pf,
    then: (validationSchema) => validationSchema.equals(Object.values(EMaritalStatus), 'Estado civil inválido').required('Estado civil é obrigatório'),
    otherwise: (validationSchema) => validationSchema.nullable()
  }),
  uf: yup.string().required('Campo obrigatório'),
  dealershipId: yup.string().required('Campo obrigatório'),
  nationality: yup.string().when('personType', {
    is: (value: ECustomerPersonType) => value === ECustomerPersonType.pf,
    then: (validationSchema) => validationSchema.required('Nacionalidade é obrigatório'),
    otherwise: (validationSchema) => validationSchema.nullable()
  }),
  averageEnergyBillValue: yup.number().required('Campo obrigatório'),
  email: yup.string().required('Email é obrigatório').email('Email inválido'),
  name: yup.string().required('Nome completo é obrigatório').min(3, 'Nome deve possuir no mínimo 3 dígitos'),
})

export const BaseData: React.FC<PropsWithWizard> = (props) => {
  const { proposal, setProposal } = useProposalContext()
  const [proposalCreate, { loading: proposalCreateLoading, data: createdProposal, reset }] = useProposalCreateMutation()
  const [proposalUpdate, { loading: updateProposalLoading }] = useProposalUpdateMutation()
  const [solveCustomerPendencies, { loading: solveCustomerPendenciesLoading }] = useSolveProposalCustomerPendenciesMutation()
  const { data: dealerships, loading: loadingDealerships } = useDealershipListQuery({ variables: { params: { pageSize: 99999, available: true } } })
  const [filteredDealerships, setFilteredDealerships] = useState<Option[]>([defaultOption])

  const methods = useForm<BaseDataForm>({
    resolver: yupResolver(schema),
    values: getInitialValues(proposal)
  })

  const state = methods.watch('uf')
  const personTypeWatch = methods.watch('personType')
  const isPf = personTypeWatch === ECustomerPersonType.pf
  const disableFields = Boolean(proposal?.status !== EProposalStatus.documentation && proposal?._id)

  useEffect(() => {
    if (proposal) {
      const { customerRef } = proposal
      const { pendencies, averageEnergyBillValue } = customerRef

      methods.setValue('averageEnergyBillValue', averageEnergyBillValue ? transformMoney(averageEnergyBillValue, 'toReal') : 0)
      pendencies && Object.keys(pendencies).forEach((item) => {
        isPendencyField(item as keyof ProposalCustomerPendenciesFields, pendencies) && methods.setError(item as keyof BaseDataForm, { message: 'Dado anterior recusado. Por favor, informe um dado válido.' })
      })
    }
  }, [proposal])

  const createProposal = async (formData: BaseDataForm) => {
    const { name, document, email, personType, maritalStatus, nationality, phone, averageEnergyBillValue, dealershipId, uf } = formData
    
    const baseParams: ProposalCreateGraphqlDto = {
      uf,
      name,
      email,
      personType,
      dealershipId,
      phone: clearString(phone),
      document: clearString(document),
      origin: EProposalOrigin.internal,
      averageEnergyBillValue: averageEnergyBillValue ? transformMoney(averageEnergyBillValue) : 0,
    }

    const params = isPf ? { ...baseParams, maritalStatus, nationality } : baseParams

    try {
      await proposalCreate({ variables: { params } })
    } catch (err) {
      const apolloError = err as ApolloError
      toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, apolloError.message || 'Houve um problema ao criar a proposta'), {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  const updateProposal = async (proposalArg: ProposalModel, formData: BaseDataForm) => {
    const { averageEnergyBillValue, dealershipId, dealershipName, email, maritalStatus, name, nationality, personType, phone, uf } = formData
    let updatedProposal: ProposalModel | null = null

    const params: ProposalUpdateGraphqlDto = {
      _id: proposalArg?._id,
      uf,
      name,
      email,
      personType,
      nationality,
      dealershipId,
      maritalStatus,
      dealershipName,
      phone: clearString(phone),
      averageEnergyBillValue: averageEnergyBillValue ? transformMoney(averageEnergyBillValue) : 0
    }

    try {
      const { data } = await proposalUpdate({ variables: { params } })

      if (data) {
        updatedProposal = data.proposalUpdate as ProposalModel
      }

      const solvedPendencies = getProposalSolvedPendencies(formData, proposal)

      if (!isEmpty(solvedPendencies)) {
        const { data: solvedPendenciesProposal } = await solveCustomerPendencies({
          variables: {
            params: {
              proposalId: proposal?._id || '',
              pendencies: solvedPendencies
            }
          }
        })

        updatedProposal = solvedPendenciesProposal?.solveProposalCustomerPendencies as ProposalModel
      }

      updatedProposal && setProposal(updatedProposal)
      toast.success('Dados salvos com sucesso!', {
        autoClose: 3000,
        position: 'top-right'
      })
    } catch (err) {
      const apolloError = err as ApolloError
      toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, apolloError.message || 'Houve um problema ao atualizar a proposta'), {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  const onSubmit = useCallback(async (formData: BaseDataForm) => {
    if (!proposal) {
      await createProposal(formData)
      return
    }

    await updateProposal(proposal, formData)
  }, [proposal, createProposal, updateProposal])

  useEffect(() => {
    if (dealerships && dealerships.dealershipList.data.length !== 0 && state) {
      const filteredValues = dealerships.dealershipList.data.filter(item => item.locationUf === state)
        .map(item => {
          return {
            label: item.name || '',
            value: item._id
          }
        })

      methods.setValue('dealershipId', '')
      if (filteredValues.length !== 0) {
        setFilteredDealerships(filteredValues)
      } else {
        setFilteredDealerships([defaultOption])
      }
    }
  }, [state, dealerships, methods])

  useEffect(() => {
    methods.setValue('dealershipId', proposal?.customerRef.dealershipId || '')
  }, [filteredDealerships])

  return (
    <FormProvider {...methods}>
      <Form>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
          {proposal && <ProposalInfo />}

          <Typography variant='h3'>Tipo de pessoa:</Typography>
          <Radio disabled={Boolean(proposal)} row name='personType' options={personTypeOptions} />

          <Typography variant='h3'>Dados pessoais</Typography>
          <Grid spacing={3} container>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Input disabled={disableFields} name='name' label='Nome completo' />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Input disabled={Boolean(proposal?._id)} name='document' label={isPf ? 'CPF' : 'CNPJ'} mask={isPf ? 'cpf' : 'cnpj'} />
            </Grid>

            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Input disabled={disableFields} name='email' label='E-mail' />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Input disabled={disableFields} name='phone' label='Telefone' mask='phone' />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Select disabled={disableFields} name='uf' label='UF' options={brazilStates} />
            </Grid>

            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Select
                name='dealershipId'
                disabled={loadingDealerships || disableFields}
                options={filteredDealerships}
                label='Concessionária de energia'
                startAdornment={loadingDealerships ? <CircularProgress size={18} color='inherit' sx={{ marginLeft: 2 }} /> : null}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Input disabled={disableFields} mask='BRL' name='averageEnergyBillValue' label='Valor médio de consumo' />
            </Grid>
          </Grid>

          {isPf && (
            <>
              <Typography variant='h3'>Dados complementares</Typography>
              <Grid spacing={2} container>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Input disabled={disableFields} name='nationality' label='Nacionalidade' />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Select disabled={disableFields} name='maritalStatus' label='Estado civil' options={maritalStatusOptions} />
                </Grid>
              </Grid>
            </>
          )}

          <ActionButtons onSubmit={methods.handleSubmit(onSubmit)} loading={updateProposalLoading || solveCustomerPendenciesLoading} {...props} />
          <CreatingProposalDialog proposal={createdProposal} loading={proposalCreateLoading} isVisible={proposalCreateLoading || Boolean(createdProposal?.proposalCreate)} onClose={reset} />
        </Box>
      </Form>
    </FormProvider>
  )
}
