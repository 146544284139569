import { useEffect, useMemo, useState } from 'react'
import { Box, Card, IconButton, Tooltip, useMediaQuery, styled } from '@mui/material'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { IconDownload, } from '@tabler/icons-react'
import { TableApiV2, TableHeaderApi, ContentTitle, FilterButton } from '~/components'
import { SearchQueysData, useDebounce, useQueryString, useToggle } from '~/hooks'
import { formatDate, formatMoney, monthNameByMonthNumber, theme } from '~/utils'
import { useInvoiceListQuery, } from '~/graphql/types'
import { FinancialStatus } from '~/components/FinancialStatus'
import { FilterInvoiceDrawer } from './components/FilterInvoiceDrawer'

const StyledTable = styled(Box)`
overflow-x: auto;
width: 100%;

.MuiTableCell-root {
  min-width: 125px;
}
`

export const InvoicesList: React.FC = () => {
  const { isTrue: drawerIsOpen, toggle: toggleDrawer, setIsTrue: setDrawerIsOpen } = useToggle(false)

  const queryKeys: SearchQueysData[] = [
    { key: 'status', defaultValue: [], parseValue: true },
    // { key: 'name', defaultValue: '' },
    { key: 'begin', defaultValue: '' },
    { key: 'end', defaultValue: '' },
  ]
  const { searchQuerys, paginationQuerySize, searchParams, setSearchParams, page, size } = useQueryString()

  const queryValues = searchQuerys(queryKeys)
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [filterName, setFilterName] = useState(queryValues.name)

  const debouncedFilterName = useDebounce(filterName, 1000)

  useEffect(() => {
    setSearchParams(state => {
      if (debouncedFilterName) {
        state.set('name', debouncedFilterName)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }, [debouncedFilterName])

  const { data, loading } = useInvoiceListQuery({
    variables: {
      params: {
        pageSize: size,
        pageNumber: page,
        sort: {
          field: 'dueDate',
          order: -1
        },

        // ...queryValues?.name?.length > 0 ? { nameOrDocumentOrEmail: ifIsDocumentReturnFormated(queryValues.name) } : {},
        ...queryValues.status?.length > 0 ? { inStatus: queryValues.status } : {},
        ...queryValues?.begin && queryValues?.end ?
          {
            dueDate:
            {
              startDate: new Date(queryValues.begin),
              endDate: new Date(`${queryValues.end}Z23:59:59`)
            }
          }
          : {},
      }
    },
    fetchPolicy: 'no-cache',
    onCompleted(result) {
      setSearchParams(state => {
        state.set('items', String(result.invoiceList.meta.documentCounts))
        return state
      })
    }
  })

  const columns = useMemo((): Column[] => {
    return [
      // {
      //   Header: 'Cliente',
      //   accessor: 'withdrawn.name',
      //   Cell: ({ value }) => <>{trimString(value, { maxTextLength: isLowerSm ? 12 : 20, textCutLength: isLowerSm ? 12 : 20 })}</>,
      // },
      {
        Header: 'Referência',
        accessor: 'energyBill',
        Cell: (props) => <>{monthNameByMonthNumber(props.value.reference.month)}, {Number(props.value.reference.year) + 2000}</>
      },
      {
        Header: 'Data de criação',
        accessor: 'createdAt',
        Cell: (props) => <>{formatDate(props.value)}</>,

      },
      {
        Header: 'Vencimento',
        accessor: 'dueDate',
        Cell: (props) => <>{formatDate(props.value)}</>
      },
      {
        Header: 'Pagamento',
        accessor: 'payment.paidAt',
        Cell: (props) => <>{formatDate(props.value) || '-'}</>,
      },
      {
        Header: 'Valor',
        accessor: 'amountCents',
        Cell: (props) => <>{formatMoney(props.value / 100) || '-'}</>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: (props) => <FinancialStatus status={props.value} />
      },
      {
        Header: 'Ações',
        accessor: '_id',
        Cell: ({ value }) => (

          <a target='_blank' download href={`${import.meta.env.VITE_APP_REST_API_URL}customer-area/download/invoice/${value}`} rel='noreferrer'>

            <IconButton>
              <Tooltip placement='top' title='Fazer download da fatura'>
                <IconDownload />
              </Tooltip>
            </IconButton>

          </a>

        )
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => data?.invoiceList.data || [], [data])

  // const defaultColumnHiddens = isLowerlg ? ['createdAt', 'payment.paidAt'].concat(isLowerMd ? ['referenceOrder'] : []).concat(isLowerSm ? ['dueDate'] : '') : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    defaultColumn: {
      Filter: <></>
    },
    manualPagination: true,
    initialState: {
      // hiddenColumns: defaultColumnHiddens
    }
  }, useFilters, useSortBy, usePagination, useRowSelect
  )

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title={'Faturas'}
          description={'Visualize e gerencie todas as faturas geradas'}
          breadcrumbLinks={{ currentLink: 'Faturas', links: [{ href: '/app/financials', label: 'Financeiro' }] }}
        />
        <TableHeaderApi
          startElement={
            (
              <Box sx={{
                display: 'flex',
                // justifyContent: 'space-between',
                justifyContent: 'flex-end',
                gap: '1rem', width: '100%',
                flexFlow: isLowerSm ? 'column-reverse' : 'row'
              }}>
                {/* <TextField
                  fullWidth
                  sx={{ maxWidth: '400px' }}
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  placeholder='Buscar por nome, doc. ou n.º de instalação'
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position='end'>
                          <IconSearch />
                        </InputAdornment>
                      </>
                    )
                  }}
                /> */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <FilterButton onCustomClear={() => setFilterName('')} filtersToClear={queryKeys} toggleDrawer={toggleDrawer} filterCounter={searchParams.size - paginationQuerySize} />
                </Box>

                <FilterInvoiceDrawer queryKeys={queryKeys} drawerIsOpen={drawerIsOpen} setDrawerIsOpen={setDrawerIsOpen} toggleDrawer={toggleDrawer} />
              </Box>
            )
          }
        />
        <StyledTable className='testeeeeee'>
          <TableApiV2
            isLoading={loading}
            tableMethods={tableMethods}
            columns={columns}
          />
        </StyledTable>
      </Card>
    </div>
  )
}
