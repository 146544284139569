import { Box, Button, Typography, Checkbox as MuiCheckbox, FormControlLabel, useMediaQuery } from '@mui/material'
import { StepTitle } from '../StepTitle'
import { useForm, FormProvider, useWatch, Controller } from 'react-hook-form'
import { Input } from '~/components/Form/Input'
import { Checkbox } from '~/components/Form/Checkbox'
import { IconCheck, IconX } from '@tabler/icons-react'
import { AddressGraphqlModel, ProposalWithDealershipsQuery } from '~/graphql/types'
import { useEffect } from 'react'
import { InputPendenciesStatus } from '../InputPendenciesStatus'
import { FieldsType } from '../types'
import { useAuth } from '~/contexts'
import { theme } from '~/utils'

type AddressProps = {
  proposal: ProposalWithDealershipsQuery['proposalGet']
  onRefuse: Function
  onApprove: Function
  modalType: 'approve' | 'refuse'
  setFields: React.Dispatch<React.SetStateAction<FieldsType>>
}

type FormData = AddressGraphqlModel & {
  pendencies: {
    city: boolean;
    complement?: boolean;
    country: boolean;
    neighborhood: boolean;
    number: boolean;
    state: boolean;
    street: boolean;
    zipcode: boolean;
  }
  controlPendencies: boolean;
}

export const Address: React.FC<AddressProps> = ({ proposal, onApprove, onRefuse, setFields }) => {
  const formMethods = useForm<FormData>()
  const { authUser } = useAuth()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isSeller = authUser?.user.type === 'seller'

  useEffect(() => {
    if (proposal?.customerRef.address) {
      formMethods.setValue('zipcode', proposal?.customerRef.address.zipcode)
      formMethods.setValue('city', proposal?.customerRef.address.city)
      formMethods.setValue('state', proposal?.customerRef.address.state)
      formMethods.setValue('complement', proposal?.customerRef.address.complement)
      formMethods.setValue('neighborhood', proposal?.customerRef.address.neighborhood)
      formMethods.setValue('street', proposal?.customerRef.address.street)
      formMethods.setValue('number', proposal?.customerRef.address.number)
    }
  }, [proposal])

  function handleSubmit(data: FormData) {
    const selectedFields = Object?.entries(data?.pendencies).filter(item => item[1])
    const objectFields = Object.fromEntries(selectedFields)
    setFields(objectFields)
  }

  const { pendencies } = useWatch({
    control: formMethods.control,
  })

  function onSelectAllFields(isValid: boolean) {
    formMethods.setValue('controlPendencies', !isValid)
    formMethods.setValue('pendencies.zipcode', !isValid)
    formMethods.setValue('pendencies.street', !isValid)
    formMethods.setValue('pendencies.number', !isValid)
    formMethods.setValue('pendencies.state', !isValid)
    formMethods.setValue('pendencies.city', !isValid)
    formMethods.setValue('pendencies.neighborhood', !isValid)
    if (proposal?.customerRef.address?.complement) {
      formMethods.setValue('pendencies.complement', !isValid)
    }
  }

  const enableSelectAll = [
    Boolean(!proposal?.customerRef?.address?.zipcode),
    Boolean(!proposal?.customerRef?.address?.street),
    Boolean(!proposal?.customerRef?.address?.number),
    Boolean(!proposal?.customerRef?.address?.state),
    Boolean(!proposal?.customerRef?.address?.city),
    Boolean(!proposal?.customerRef.address?.neighborhood)
    // Boolean(!proposal?.customerRef.address?.complement)
  ].some(item => item)

  const buttonIsEnable = pendencies && Object?.values(pendencies)?.some(item => item)

  useEffect(() => {
    if (proposal) {
      formMethods.setValue('pendencies', {
        city: false,
        country: false,
        neighborhood: false,
        number: false,
        state: false,
        street: false,
        zipcode: false,
        complement: false,
      })
    }
  }, [proposal])

  return (
    <>
      <StepTitle proposalId={proposal?._id} clientName={proposal?.customerRef.name} step={{ value: '2', title: 'Endereço' }} />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => handleSubmit(data))}>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '1rem 0 1rem 0', justifyContent: 'space-between' }}>

            <Typography sx={{ padding: '1rem 0 0 0' }} variant='h6'>Endereço do cliente</Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
              {!isLowerSm &&
                <Typography fontWeight={500} color={theme.palette.grey[600]} fontSize='.95rem' variant='h6'>Selecionar todos</Typography>
              }
              <Controller
                name='controlPendencies'
                control={formMethods.control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    sx={{ margin: 0 }}
                    onChange={(e: any) => {
                      field.onChange(e)
                      onSelectAllFields(field.value)
                    }}
                    control={<MuiCheckbox disabled={enableSelectAll} />}
                    label={<Typography variant='body2' color='grey.400'></Typography>}
                  />
                )}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '1rem 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.zipcode} /> } }}
              mask='cep'
              disabled
              name='zipcode'
              label='CEP'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.address?.zipcode) }} name='pendencies.zipcode' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.street} /> } }}
              disabled
              name='street'
              label='Rua'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.address?.street) }} name='pendencies.street' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.number} /> } }}
              disabled
              name='number'
              label='Número'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.address?.number) }} name='pendencies.number' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.state} /> } }}
              disabled
              name='state'
              label='Estado'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.address?.state) }} name='pendencies.state' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.city} /> } }}
              disabled
              name='city'
              label='Cidade'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.address?.city) }} name='pendencies.city' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.neighborhood} /> } }}
              disabled
              name='neighborhood'
              label='Bairro'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.address?.neighborhood) }} name='pendencies.neighborhood' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.complement} /> } }}
              disabled
              name='complement'
              label='Complemento (opcional)'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.address?.complement) }} name='pendencies.complement' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end', flexFlow: isLowerSm ? 'column' : 'row' }}>
            <Button type='submit' disabled={!buttonIsEnable || isSeller} onClick={() => onRefuse()} startIcon={<IconX />} color='secondary'>Recusar selecionados</Button>
            <Button type='submit' disabled={!buttonIsEnable || isSeller} onClick={() => onApprove()} startIcon={<IconCheck />}>Aprovar selecionados</Button>
          </Box>
        </form>
      </FormProvider>

    </>
  )
}
