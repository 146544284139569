import { Box, Button, Card, CircularProgress, Grid, Tooltip, Typography } from '@mui/material'
import { IconArrowLeft, IconCheck, IconInfoCircle, IconPercentage } from '@tabler/icons-react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ContentTitle, PageLoader } from '~/components'
import { Input } from '~/components/Form/Input'
import { Radio } from '~/components/Form/Radio'
import { Select } from '~/components/Form/Select'
import { EPartnerStatus, EPersonType, FileUploadInputTypeDto, PartnerDiscountExceptionCreateDto, PartnerModel, useDealershipListQuery, usePartnerCreateMutation, usePartnerGetQuery, usePartnerUpdateMutation } from '~/graphql/types'
import { booleanOptions, partnerStatusOptions, personTypeOptions } from '~/utils/options'
import { DiscountExceptionTable, PictureCroped } from './components'
import { useTheme } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import { GridColumn } from '~/styles'
import { useCallback, useEffect, useState } from 'react'
import { clearString } from '~/utils'
import * as yup from 'yup'
import { documentSchema, phoneSchema } from '~/utils/yupSchema'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorText } from '~/components/Form/ErrorText'

export type FormData = {
  personType: EPersonType
  name: string
  code: string
  document: string
  email: string
  phone: string
  site: string
  status: string
  contractPerson: {
    name: string
    email: string
    phone: string
  }
  partnershipConditions: {
    alwaysCommissionedInvoices: boolean
    baseComissionPercentage: number
    commissionedInvoices: string
  }
  hasDiscountsException: boolean
  discountsException: {
    uf: string
    dealershipId: string
    customerPFDiscountMax?: number
    customerPJDiscountMax?: number
    customerPJDiscountSuggested?: number
    customerPFDiscountSuggested?: number
    customerPFDiscountMin?: number
    customerPJDiscountMin?: number
  }[]
  regularLogo?: FileUploadInputTypeDto | null
  reducedLogo?: FileUploadInputTypeDto | null
}

export const PartnerCreateEdit: React.FC = () => {
  const { id } = useParams()

  const schema = yup
    .object({
      code: yup.string().optional(),
      document: documentSchema,
      email: yup.string().email().required(),
      name: yup.string().required(),
      personType: yup.string().required(),
      phone: phoneSchema,
      site: yup.string().optional(),

      discountsException: yup.array().of(
        yup.object({
          uf: yup.string().optional(),
          dealershipId: yup.string().required(),
          customerPFDiscountMax: yup.number().max(100).required(),
          customerPJDiscountMax: yup.number().max(100).required(),
          customerPJDiscountSuggested: yup.number().max(
            yup.ref('customerPJDiscountMax'),
            'Benefício sugerido para PJ não pode ser maior que o máximo.'
          ).required(),
          customerPFDiscountSuggested: yup.number().max(
            yup.ref('customerPFDiscountMax'),
            'Benefício sugerido para PF não pode ser maior que o máximo.'
          ).required(),
          customerPFDiscountMin: yup.number().max(100).required(),
          customerPJDiscountMin: yup.number().max(100).required(),
        })
      ),

      partnershipConditions: yup.object({
        alwaysCommissionedInvoices: yup.boolean(),
        baseComissionPercentage: yup.number().max(100),
        commissionedInvoices: yup.string()
          .test('isValid', (value, validation) => {
            if (validation.parent.alwaysCommissionedInvoices && value?.length === 0) {
              return validation.createError({
                message: 'Este campo é obrigatório.',
                path: 'partnershipConditions.commissionedInvoices'
              })
            }
            return true
          }),
      }),

      contractPerson: yup.object({
        name: yup.string().required(),
        email: yup.string().email().required(),
        phone: phoneSchema,
      }),

      regularLogo: yup.object({
        fileName: yup.string().optional(),
        fileUrl: yup.string().optional(),
        key: yup.string().optional(),
        storageService: yup.string().optional(),
      }).nullable().default(null)
        .when({
          is: () => Boolean(!id),
          then: (validationSchema) => validationSchema.required(),
          otherwise: (validationSchema) => validationSchema.optional()
        })
      ,

      reducedLogo: yup.object({
        fileName: yup.string().optional(),
        fileUrl: yup.string().optional(),
        key: yup.string().optional(),
        storageService: yup.string().optional(),
      }).nullable().default(null)
        .when({
          is: () => Boolean(!id),
          then: (validationSchema) => validationSchema.required(),
          otherwise: (validationSchema) => validationSchema.optional()
        }),

    }).required()

  const theme = useTheme()
  const navigate = useNavigate()
  const [selectedPartner, setSelectedPartner] = useState<null | PartnerModel>(null)
  const [partnerIsLoading, setPartnerIsLoading] = useState(true)
  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      reducedLogo: null,
      regularLogo: null,
      hasDiscountsException: false,
      partnershipConditions: {
        alwaysCommissionedInvoices: false,
        baseComissionPercentage: 0
      }
    }
  })
  const { personType, hasDiscountsException, partnershipConditions, discountsException: discountsExceptionWatch } = useWatch({ control: formMethods.control })

  const { data: dealershipList } = useDealershipListQuery({
    variables: {
      params: {
        pageSize: 999999
      }
    }
  })

  const { refetch: refetchPartnerGet } = usePartnerGetQuery({
    variables: {
      id: id || ''
    },
    skip: Boolean(!id),
    onCompleted(data) {
      setSelectedPartner(data.partnerGet as PartnerModel)
      setPartnerIsLoading(false)
    },
    onError: (error) => {
      setPartnerIsLoading(false)
      toast.error(error.message)
    }
  })

  const [createPartner, { loading: partnerCreateIsLoading }] = usePartnerCreateMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted() {
      toast.success('O parceiro foi cadastrado!')
      navigate('/app/partners')
    },
  })

  const [updatePartner, { loading: partnerUpdateIsLoading }] = usePartnerUpdateMutation({
    onCompleted() {
      toast.success('O parceiro foi alterado!')
      navigate('/app/partners')
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })

  useEffect(() => {
    if (id) {
      refetchPartnerGet()
    } else {
      setSelectedPartner(null)
      setPartnerIsLoading(false)
    }
  }, [id])

  useEffect(() => {
    if (id && selectedPartner && dealershipList && dealershipList?.dealershipList?.data?.length > 0) {
      formMethods.setValue('name', selectedPartner?.name)
      formMethods.setValue('code', selectedPartner?.code || '')
      formMethods.setValue('document', selectedPartner?.document)
      formMethods.setValue('email', selectedPartner?.email)
      formMethods.setValue('phone', selectedPartner?.phone)
      formMethods.setValue('site', selectedPartner?.site || '')
      formMethods.setValue('status', selectedPartner?.status)
      formMethods.setValue('personType', selectedPartner?.personType)

      if (selectedPartner.contactPerson) {
        formMethods.setValue('contractPerson', selectedPartner?.contactPerson)
      }

      if (selectedPartner.discountsException.length > 0) {
        formMethods.setValue('hasDiscountsException', true)
        formMethods.setValue('discountsException', selectedPartner.discountsException.map(item => {
          const uf = dealershipList?.dealershipList.data.find(dealership => dealership._id === item.dealershipId)?.locationUf || ''
          return {
            dealershipId: item.dealershipId,
            uf,
            customerPFDiscountMax: item.customerPFDiscountMax,
            customerPFDiscountMin: item.customerPFDiscountMin,
            customerPFDiscountSuggested: item.customerPFDiscountSuggested,
            customerPJDiscountMax: item.customerPJDiscountMax,
            customerPJDiscountMin: item.customerPJDiscountMin,
            customerPJDiscountSuggested: item.customerPJDiscountSuggested,
          }
        }))
      } else {
        formMethods.setValue('hasDiscountsException', false)
        formMethods.setValue('discountsException', [])
      }

      formMethods.setValue('partnershipConditions.baseComissionPercentage', selectedPartner.partnershipConditions?.baseComissionPercentage || 0)
      if (selectedPartner.partnershipConditions?.alwaysCommissionedInvoices) {
        formMethods.setValue('partnershipConditions.alwaysCommissionedInvoices', true)
        formMethods.setValue('partnershipConditions.commissionedInvoices', String(selectedPartner.partnershipConditions?.commissionedInvoices || 0))
      } else {
        formMethods.setValue('partnershipConditions.alwaysCommissionedInvoices', false)
      }
      formMethods.setValue('regularLogo', undefined)

    } else {
      formMethods.reset()
    }
  }, [selectedPartner, id, dealershipList])

  const onSubmit = useCallback((data: FormData) => {

    const formatedDiscountExecption: PartnerDiscountExceptionCreateDto[] =
      data.hasDiscountsException ? data.discountsException.map(item => ({
        customerPFDiscountMax: item.customerPFDiscountMax || 0,
        customerPFDiscountMin: item.customerPFDiscountMin || 0,
        customerPFDiscountSuggested: item.customerPFDiscountSuggested || 0,
        customerPJDiscountMax: item.customerPJDiscountMax || 0,
        customerPJDiscountMin: item.customerPJDiscountMin || 0,
        customerPJDiscountSuggested: item.customerPJDiscountSuggested || 0,
        dealershipId: item.dealershipId,
      })) : []

    if (id) {
      updatePartner({
        variables: {
          params: {
            partnerId: id || '',
            phone: clearString(data.phone),
            status: data.status as EPartnerStatus,
            ...data.site.length > 0 ? { site: data.site } : {},
            ...data.reducedLogo || data.reducedLogo ? {
              ...data.regularLogo ? { icone: data.regularLogo } : {},
              ...data.reducedLogo ? { icone: data.reducedLogo } : {},
            } : {},
            discountsException: formatedDiscountExecption,
            contactPerson: {
              name: data.contractPerson.name,
              email: data.contractPerson.email,
              phone: clearString(data.contractPerson.phone),
            }
          }
        }
      })
    } else {
      createPartner({
        variables: {
          params: {
            document: clearString(data.document),
            name: data.name,
            email: data.email,
            personType: data.personType,
            phone: clearString(data.phone),
            code: data.code,
            ...data.site.length > 0 ? { site: data.site } : {},
            configuration: {
              allowAffiliateLink: true,
              allowManualProposal: true,
              logo: data.regularLogo!,
              icone: data.reducedLogo!,
            },
            partnershipConditions: {
              alwaysCommissionedInvoices: data.partnershipConditions.alwaysCommissionedInvoices,
              baseComissionPercentage: data.partnershipConditions.baseComissionPercentage,
              ...data.partnershipConditions.alwaysCommissionedInvoices ? { commissionedInvoices: Number(data.partnershipConditions.commissionedInvoices) } : {}
            },
            contactPerson: {
              email: data.contractPerson.email,
              name: data.contractPerson.name,
              phone: clearString(data.contractPerson.phone),
            },
            discountsException: formatedDiscountExecption
          }
        }
      })
    }

  }, [id])

  const partnerImages = {
    logo: selectedPartner?.code ? `${process.env.VITE_APP_REST_API_URL}partners/logo/${selectedPartner?.code}` : null,
    icone: selectedPartner?.code ? `${process.env.VITE_APP_REST_API_URL}partners/icone/${selectedPartner?.code}` : null
  }

  const canRemoveException = Boolean(discountsExceptionWatch && discountsExceptionWatch?.length > 0)

  const isValidForm = formMethods.formState.isValid

  if (partnerIsLoading) return <PageLoader />

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem 1rem 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title={id ? 'Editar parceiro' : 'Cadastrar parceiro'}
          description={id ? 'Edição e gestão de parceiros Desperta' : 'Cadastro e gestão de parceiros Desperta'}
          breadcrumbLinks={{ currentLink: id ? 'Editar Parceiros' : 'Cadastrar Parceiros', links: [{ href: '/app/partners', label: 'Parceiros' }] }}
        />
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
            <Box>
              <Typography color={theme.palette.grey[800]} sx={{ fontWeight: 500, paddingBottom: '1rem' }} variant='h3'>Personalização da plataforma</Typography>
              <GridColumn columns={2} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 1 }]}>
                <Box>
                  <PictureCroped partnerLogo={partnerImages.logo} typeLogo='defaultLogo' />
                  <ErrorText $margin='-20px 0 0 0' error={formMethods.formState.errors.regularLogo ? 'Marca regular é obrigatório' : undefined} />
                </Box>
                <Box>
                  <PictureCroped partnerLogo={partnerImages.icone} typeLogo='reducedLogo' />
                  <ErrorText $margin='-20px 0 0 0' error={formMethods.formState.errors.reducedLogo ? 'Marca reduzida é obrigatório' : undefined} />
                </Box>
              </GridColumn>
            </Box>

            <Typography color={theme.palette.grey[800]} sx={{ fontWeight: 500, fontSize: '24px', padding: '1rem 0 .4rem 0' }}>Tipo de pessoa:</Typography>
            <Radio disabled={Boolean(id)} row name='personType' options={personTypeOptions} />

            <Typography color={theme.palette.grey[800]} sx={{ fontWeight: 500, fontSize: '24px', padding: '1rem 0 .4rem 0' }}>Dados básicos:</Typography>
            <Grid spacing={2} container>
              <Grid item xs={12} sm={4} md={4}>
                <Input disabled={Boolean(id)} name='name' label='Nome do parceiro' />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Input disabled={Boolean(id)} name='code' label='Código do parceiro' />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Input disabled={Boolean(id)} mask={personType === EPersonType.pf ? 'cpf' : 'cnpj'} name='document' label={personType === EPersonType.pf ? 'CPF' : 'CNPJ'} />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Input disabled={Boolean(id)} name='email' label='E-mail' />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Input mask='phone' name='phone' label='Telefone' />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Input name='site' label='Site (Opcional)' />
              </Grid>
              {Boolean(id) && (
                <Grid item xs={12} sm={4} md={4}>
                  <Select name='status' label='Status' options={partnerStatusOptions} />
                </Grid>
              )}
            </Grid>

            <Typography color={theme.palette.grey[800]} sx={{ fontWeight: 500, fontSize: '16px', padding: '1rem 0 .4rem 0' }}>Dados do representante:</Typography>

            <Grid spacing={2} container>
              <Grid item xs={12} sm={12} md={8}>
                <Input name='contractPerson.name' label='Nome completo' />
              </Grid>
              <Grid sx={{ padding: '0 !important' }} item xs={12} sm={12} md={12} />
              <Grid item xs={12} sm={6} md={4}>
                <Input name='contractPerson.email' label='E-mail' />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Input mask='phone' name='contractPerson.phone' label='Telefone' />
              </Grid>
            </Grid>

            <Typography color={theme.palette.grey[800]} sx={{ fontWeight: 500, fontSize: '24px', padding: '1rem 0 .4rem 0' }}>Condições comerciais</Typography>

            <Grid spacing={2} container>
              <Grid item xs={12} sm={6} md={4}>
                <Input disabled={Boolean(id)} icons={{ end: { element: <IconPercentage /> } }} mask='percentage' name='partnershipConditions.baseComissionPercentage' label='Percentual de comissão base' />
              </Grid>
            </Grid>

            <Typography color={theme.palette.grey[800]} sx={{ fontWeight: 500, fontSize: '16px', padding: '1.4rem 0 .4rem 0' }}>Definir número de faturas comissionadas?</Typography>
            <Radio disabled={Boolean(id)} useDefaultValue={Boolean(id)} row name='partnershipConditions.alwaysCommissionedInvoices' options={booleanOptions} />

            {partnershipConditions?.alwaysCommissionedInvoices && (
              <Grid spacing={2} container>
                <Grid item xs={12} sm={6} md={4}>
                  <Input disabled={Boolean(id)} type='number' name='partnershipConditions.commissionedInvoices' label='Número de faturas comissionadas' />
                </Grid>
              </Grid>
            )}

            <Typography color={theme.palette.grey[800]} sx={{ fontWeight: 500, fontSize: '24px', padding: '1rem 0 .4rem 0' }}>Benefício do cliente</Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem' }}>
              <Typography color={theme.palette.grey[800]} sx={{ fontWeight: 500, fontSize: '16px', padding: '.2rem 0 .4rem 0' }}>Exceção ao benefício de concessionárias?</Typography>
              <Tooltip title='Indica se o parceiro pode oferecer % de benefício diferente do cadastrado na concessionária ao cliente'>
                <IconInfoCircle color={theme.palette.info.dark} />
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', gpa: '1rem', alignItems: 'center' }}>
              <Radio disabled={canRemoveException} useDefaultValue={Boolean(id)} row name='hasDiscountsException' options={booleanOptions} />
              {canRemoveException && (
                <Tooltip title='Para poder alterar a exceção de benefício, é preciso remover todas as concessionárias cadastradas'>
                  <IconInfoCircle color={theme.palette.info.dark} />
                </Tooltip>
              )}
            </Box>
            
            {hasDiscountsException &&
              <DiscountExceptionTable selectedPartner={selectedPartner} dealerships={dealershipList?.dealershipList.data || []} />
            }

            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '2rem 0 1rem 0', gap: '1rem' }}>
              <Button onClick={() => navigate('/app/partners')} color='secondary'>
                <IconArrowLeft />
                Voltar
              </Button>
              {!isValidForm ? (
                <Box>
                  <Tooltip title='Preencha todos os campos obrigatórios'>
                    <Button disabled={false} type='submit'>
                      <IconCheck />
                      {id ? 'Editar parceiro' : 'Confirmar cadastro'}
                    </Button>
                  </Tooltip>
                </Box>
              ) : (
                <Button
                  endIcon={partnerCreateIsLoading || partnerUpdateIsLoading ? <CircularProgress size={24} color='inherit' /> : null}
                  disabled={partnerCreateIsLoading || partnerUpdateIsLoading} type='submit'>
                  <IconCheck
                  />
                  {id ? 'Editar parceiro' : 'Confirmar cadastro'}
                </Button>
              )}
            </Box>
          </form>
        </FormProvider>

      </Card>
    </div>
  )
}
