import { Dialog } from '~/components'
import { Container } from './styles'
import { Box, Button, CircularProgress, Typography, useMediaQuery } from '@mui/material'
import { IconInfoCircle, IconHelpCircle } from '@tabler/icons-react'
import { theme, translateFieldsInput } from '~/utils'
import { FieldsType, StepPendency } from '../types'
import { ProposalModel, useAnalyseRegistrationDataMutation, usePendencyFilesProposalMutation, usePendencyRepresentativiesMutation } from '~/graphql/types'
import { toast } from 'react-toastify'

type ModalPendenciesProps = {
  isVisible: boolean,
  onClose: Function,
  type?: 'approve' | 'refuse',
  fields: FieldsType
  proposal: ProposalModel | null
  setProposal: React.Dispatch<React.SetStateAction<ProposalModel | null>>
  setFields: React.Dispatch<React.SetStateAction<FieldsType>>
  stepPendencyValue: StepPendency
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  representativeIndex: number
}

export const ModalPendencies: React.FC<ModalPendenciesProps> = ({
  isVisible,
  onClose,
  type = 'approve',
  fields,
  proposal,
  stepPendencyValue,
  setIsVisible,
  representativeIndex,
  setProposal,
}) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [pendencyFiles, { loading: pendencyFilesIsLoading }] = usePendencyFilesProposalMutation({
    onCompleted(result) {

      setProposal(prev => prev && {
        ...prev, files: result.proposalFilesAnalysis.files as any
      })

      setIsVisible(false)
      toast.success(`${type === 'approve' ? 'Os dados foram aprovados' : 'Os dados foram recusados com sucesso!'}`)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const [registerPendency, { loading: registerPendencyIsLoading }] = useAnalyseRegistrationDataMutation({
    onCompleted(result) {
      setProposal(prev => prev && {
        ...prev, customerRef: {
          ...prev.customerRef, pendencies: result.analyseRegistrationData.customerRef.pendencies as any
        }
      })

      setIsVisible(false)
      toast.success(`${type === 'approve' ? 'Os dados foram aprovados' : 'Os dados foram recusados com sucesso!'}`)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const [pendencyRepresentativies, { loading: pendencyRepresentativiesIsLoading }] = usePendencyRepresentativiesMutation({
    onCompleted(result) {

      const updatedLegalRepresentative = proposal?.customerRef.legalRepresentative
        ?.map(item => ({
          ...item,
          pendencies: result.analyseLegalRepresentativeData.customerRef.legalRepresentative
            ?.find(representative => representative.document === item.document)?.pendencies,
          files: result.proposalFilesAnalysis.customerRef.legalRepresentative
            ?.find(representative => representative.document === item.document)?.files
        }))

      setProposal(prev => prev && {
        ...prev, customerRef: {
          ...prev.customerRef, legalRepresentative: updatedLegalRepresentative as any
        }
      })

      setIsVisible(false)
      toast.success(`${type === 'approve' ? 'Os dados foram aprovados' : 'Os dados foram recusados com sucesso!'}`)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  async function onRegisterPendency() {
    if (stepPendencyValue === 'representativies') {
      const arrayFields = Object.keys(fields)
      const representativeDocument = proposal?.customerRef?.legalRepresentative && proposal?.customerRef?.legalRepresentative[representativeIndex]?.document
      const filteredArrayFields = arrayFields.filter(field => field !== 'customerPjRepresentativeDocumentWithPhotoBack' && field !== 'customerPjRepresentativeDocumentWithPhotoFront')

      const fileArrayFields = arrayFields.filter(field => field === 'customerPjRepresentativeDocumentWithPhotoBack' || field === 'customerPjRepresentativeDocumentWithPhotoFront')
      const approvePendenciesRepresentativies = Object.assign({}, ...filteredArrayFields.map(item => ({ [item]: type === 'approve' ? false : true })))
      const newObject = Object.assign(approvePendenciesRepresentativies, ...fileArrayFields.map(item => ({ [item]: type === 'approve' ? false : true })))

      pendencyRepresentativies({
        variables: {
          id: proposal?._id || '',
          dataParams: {
            proposalId: proposal?._id || '',
            pendencies: newObject,
            document: representativeDocument || ''
          },
          filesParams: {
            types: fileArrayFields as any,
            approve: type === 'approve' ? true : false,
            legalRepresentativeDocument: representativeDocument
          }
        }
      })
      return
    }

    if (stepPendencyValue === 'documents') {
      const arrayFields = Object.keys(fields)

      await pendencyFiles({
        variables: {
          id: proposal?._id || '',
          params: {
            types: arrayFields as any,
            approve: type === 'approve' ? true : false,
          }
        }
      })

      return
    }

    const approvePendencies = Object.assign({}, ...Object.keys(fields).map(item => ({ [item]: type === 'approve' ? false : true })))
    await registerPendency({
      variables: {
        params: {
          proposalId: proposal?._id || '',
          pendencies: approvePendencies
        }
      }
    })

  }

  return (
    <Container>
      <Dialog onClose={() => onClose()} isVisible={isVisible}>
        <Box sx={{ width: '100%' }}>
          {pendencyRepresentativiesIsLoading || pendencyFilesIsLoading || registerPendencyIsLoading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem' }}>
              <CircularProgress size={140} color='inherit' />
            </Box>

          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '.8rem', paddingBottom: '1.4rem' }}>
                {type === 'approve' ?
                  (
                    <>
                      <IconHelpCircle size={40} color={theme.palette.primary.main} />
                      <Typography variant='h6'>Aprovar dados</Typography>
                    </>
                  )
                  :
                  (
                    <>
                      <IconInfoCircle size={40} color={theme.palette.error.main} />
                      <Typography variant='h6'>Recuar dados</Typography>
                    </>
                  )
                }
              </Box>

              <Box sx={{ padding: '1rem 0', borderTop: '1px solid #eee', borderBottom: '1px solid #eee' }}>
                {type === 'approve' ?
                  <Typography>Deseja <b>aprovar</b> os dados selecionados?</Typography>
                  :
                  <Typography>Ao <b>recusar</b> os dados, uma pendência será gerada na proposta e não será possível continuar até que a pendência seja resolvida.</Typography>
                }

                <ul>
                  {Object.keys(fields).map(item => <li key={item}>{translateFieldsInput(item)}</li>)}
                </ul>
              </Box>

              <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'auto' : 'center', justifyContent: 'flex-end', gap: '1rem', paddingTop: '1.4rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
                <Button onClick={() => onClose()} color='secondary'>Cancelar</Button>
                {type === 'approve' ?
                  <Button onClick={() => onRegisterPendency()}>Sim, aprovar</Button>
                  :
                  <Button onClick={() => onRegisterPendency()} variant='contained' color='error'>Sim, recusar</Button>
                }

              </Box>
            </>
          )}

        </Box>
      </Dialog>
    </Container>
  )
}
